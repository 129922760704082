import React, { Component } from 'react';
import './App.css';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import Entry from './Visitor/entry';
import Exit from './Visitor/exit';
import AssociateLogins from './Login/associateLogins';
import Scan from './Scan/scan';
import Login from './Login/login';
import Home from './Login/home';
import Header from './header';
import BrowserRouter from 'react-router-dom/BrowserRouter';
import Route from 'react-router-dom/Route';
import Switch from 'react-router-dom/Switch';
import * as constants from './config/constants';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import blueGrey from '@material-ui/core/colors/blueGrey';


const muiTheme = createMuiTheme({
    palette: {
        primary: blueGrey,
    }
});


class App extends Component {
  render() {
     if(constants.isLoggedIn() === "loggedIn") {
        return (
            <div>
            <MuiThemeProvider theme={muiTheme}>
            <BrowserRouter>
            <div>
            <Header />
            <div className="App mainArea">
            <Switch>
            <Route exact={true} path='/visitor' component={Entry} />
            <Route exact={true} path='/visitorExit' component={Exit} />
            <Route exact={true} path='/scan' component={Scan} />
            <Route exact={true} path='/associateLogins' component={AssociateLogins} />
            <Route exact={true} path='/' component={Home} />
            </Switch>
            <br /><br />
            </div>
            </div>
            </BrowserRouter>
            </MuiThemeProvider>
            </div>
            );
    }
    else {
        return (
            <div>
            <MuiThemeProvider theme={muiTheme}>
            <BrowserRouter>
            <div>
            <Header />
            <div className="App mainArea">
            <Login />
            </div>
            </div>
            </BrowserRouter>
            </MuiThemeProvider>
            </div>
            );
    }
}
}
export default App;

import React, { Component } from 'react';
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import AWS from 'aws-sdk';
require('tracking');
require('tracking/build/data/face');

function dataURItoBlob(dataURI) {
	var binary = atob(dataURI.split(',')[1]);
	var array = [];
	for(var i = 0; i < binary.length; i++) {
		array.push(binary.charCodeAt(i));
	}
	return new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
}

class Face extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tracker: null,
			name: this.props.name ? this.props.name : "",
			callback: this.props.callback ? this.props.callback : null
		}
		this.onTakePhoto = this.onTakePhoto.bind(this);
	}
	onTakePhoto (dataUri) {
		let that = this;
		var s3 = new AWS.S3({
			apiVersion: '2006-03-01',
			params: {Bucket: 'ethnus-visitor-mgmt'}
		});

		var albumPhotosKey = encodeURIComponent("visitors-photos") + '/';
		var photoKey = albumPhotosKey + this.state.name + "-" + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) + '.jpg';

		s3.upload({
			Bucket: 'ethnus-visitor-mgmt',
			Key: photoKey,
			Body: dataURItoBlob(dataUri),
			ACL: 'public-read'
		}, function(err, data) {
			if (err) {
				console.log('There was an error uploading your photo: ', err.message);
				that.state.callback(false);
			} else {
				that.state.callback(data.Location);
			}
		});
	}
	componentDidMount() {
		let video = document.getElementsByTagName("video")[0];
		video.setAttribute("ref", "cameraOutput");
		let that = this;
		setTimeout(function() {
			console.log("this.refs.cameraOutput", that.refs);
		}, 1000);
		this.tracker = new window.tracking.ObjectTracker('face')
		this.tracker.setInitialScale(4)
		this.tracker.setStepSize(2)
		this.tracker.setEdgesDensity(0.1)
		window.tracking.track(video, this.tracker, { camera: true })
		this.tracker.on('track', event => {
			console.log("event.data.length", event.data.length);
			if(event.data.length === 1) {
				document.getElementById("container-circles").style.visibility = "visible";
			} else {
				document.getElementById("container-circles").style.visibility = "hidden";
			}
		});
	}
	componentWillUnmount () {
		this.tracker.removeAllListeners()
	}
	render() {
		return (
			<div>
			<div>
			<Camera
			style={{
				height: 200
			}}
			idealResolution={{
				width: 200,
				height: 200
			}}
			onTakePhoto = {dataUri => { this.onTakePhoto(dataUri); } }
			/>
			</div>
			</div>
			);
	}
}

export default Face;
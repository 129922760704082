import React, { Component} from 'react';
import Button from '@material-ui/core/Button';
import ExitToApp from '@material-ui/icons/ExitToApp';
import Icon from '@material-ui/core/Icon';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {Col,Row} from 'reactstrap';
import { Link } from "react-router-dom";
import ScreenSaver from "./screenSaver";
import IdleMonitor from 'react-simple-idle-monitor';

const styles = theme => ({
	root: {
		backgroundColor: theme.palette.background.paper,
		width: 500,
	},
});

class Home extends Component {
	constructor() {
		super();
		this.state = {
			renderScreenSaver: false
		}
	}
	onStop = () => {
		console.log("IdleMonitor onStop");
	}
	onRun = () => {
		console.log("IdleMonitor onRun");
	}
	onIdle = () => {
		let copiedStates = Object.assign({}, this.state);
		copiedStates.renderScreenSaver = true;
		this.setState(copiedStates);
	}
	onActive = () => {
		let copiedStates = Object.assign({}, this.state);
		copiedStates.renderScreenSaver = false;
		this.setState(copiedStates);
	}
	render() {
		if(this.state.renderScreenSaver) {
			return (
				<div>
				<IdleMonitor
				timeout={20000}
				onActive={this.onActive}
				onRun={this.onRun}
				onStop={this.onStop}
				onIdle={this.onIdle}
				/>
				<ScreenSaver />
				</div>
				);
		}
		return(
			<div>
			<IdleMonitor
			timeout={20000}
			onActive={this.onActive}
			onRun={this.onRun}
			onStop={this.onStop}
			onIdle={this.onIdle}
			/>
			<br />		
			<br />		
			<Row>
			<Col md={6} style={{
				textAlign: 'center'
			}} >
			<Link to="/scan" style={{
				cursor: 'pointer',
				textDecoration: 'none',
				color: 'inherit'
			}} >
			<Avatar className={"selectChoice"} src={"employee.png"} style={{
				backgroundColor: 'transparent',
				width: 250,
				height: 250,
				margin: 'auto',
				cursor: 'pointer'
			}} />
			<Typography className={"header"} style={{
				textTransform: 'uppercase',
				fontSize: '20px',
				paddingTop: '5px'
			}}>I am an Associate</Typography>
			</Link>
			</Col>
			<Col md={6} style={{
				textAlign: 'center'
			}} >
			<Link to="/visitor" style={{
				cursor: 'pointer',
				textDecoration: 'none',
				color: 'inherit'
			}} >
			<Avatar className={"selectChoice"} src={"visitor.png"} style={{
				backgroundColor: 'transparent',
				width: 250,
				height: 250,
				margin: 'auto',
				cursor: 'pointer'
			}} />
			<Typography className={"header"} style={{
				textTransform: 'uppercase',
				fontSize: '20px',
				paddingTop: '5px'
			}}>I am a Visitor</Typography>
			</Link>
			</Col>
			</Row>
			<br /><br />
			<Link to="/visitorExit">
			<Button variant="fab" color="secondary" style={{
				position: 'fixed',
				right: 30,
				bottom: 30
			}}>
			<Icon><ExitToApp /></Icon>
			</Button>
			</Link>
			</div>
			)
	}

}
Home.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Home);
import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Form } from 'react-bootstrap';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import axios from 'axios';
import {Col, Row} from 'reactstrap';
import Snackbar from '@material-ui/core/Snackbar';
import * as constants from '../config/constants';

function TabContainer({ children, dir }) {
	return (
		<Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
		{children}
		</Typography>
		);
}

TabContainer.propTypes = {
	children: PropTypes.node.isRequired,
	dir: PropTypes.string.isRequired,
};
const styles = theme => ({
	root: {
		backgroundColor: theme.palette.background.paper,
		width: 500,
	},
});
class Login extends Component {
	constructor() {
		super();
		this.state ={
			value :0,
			open : false,
			email : '',
			password : '',
			snackbarMessage : ''
		}
		this.handleLogin = this.handleLogin.bind(this);
		this.handleInputChange =this.handleInputChange.bind(this);
		this.handleClose = this.handleClose.bind(this);
	}
	handleClose() {
		this.setState({
			open : false
		})
	}
	handleInputChange(event) {
		let copiedStates = Object.assign({},this.state);
		copiedStates[event.target.name] = event.target.value;
		this.setState(copiedStates);
	}
	handleLogin(event){
		let copiedStates = Object.assign({},this.state);
		event.preventDefault();
		let req = {
			email : this.state.email,
			password : this.state.password
		}
		let that= this;
		axios.post(constants.API_BASE_URL + "/associate/signin/",req)
		.then(function(response){
			if(response.data.code === 200){
				localStorage.setItem("user", "loggedIn");
				window.location.href = constants.PRODUCT_BASE_URL+"visitor";
				copiedStates.open = true;
				copiedStates.snackbarMessage = "Success"
			} else {
				copiedStates.open = true;
				copiedStates.snackbarMessage = "Error"
			}
			that.setState(copiedStates);
		})
		.catch(function(error){
			alert(error);
		})
	}
	render() {
		return(
			<div>
			<br />		
			<br />		
			<Row>
			<Col md={4}>
			</Col>
			<Col md={4}>
			<Paper>
			<AppBar position="static" color="default">
			<Tabs
			centered
			value={this.state.value}
			indicatorColor="primary"
			textColor="primary"
			fullWidth
			>
			<Tab label="Login" />
			</Tabs>
			</AppBar>		
			<div style = {{padding : '15px'}}>
			<Form onSubmit={(event) => this.handleLogin(event)}>
			<TextField
			id="email"
			value = {this.state.email}
			placeholder="example@example.com"
			margin="normal"
			name="email"
			onChange={this.handleInputChange}
			onKeyUp={this.handleInputChange}
			fullWidth
			/>
			<TextField
			placeholder ="password"
			id="password"
			value ={this.state.password}
			margin="normal"
			name="password"
			type="password"
			onChange={this.handleInputChange}
			onKeyUp={this.handleInputChange}
			fullWidth
			/>
			<Button fullWidth variant="raised" color="primary" type="submit">
			Login
			</Button>
			</Form>
			</div>
			</Paper>
			</Col>
			<Col md={4}>
			</Col>
			</Row>
			<Snackbar
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			autoHideDuration={1000}
			open={this.state.open}
			onClose={this.handleClose}
			ContentProps={{
				'aria-describedby': 'message-id',
			}}
			message={<span id="message-id">{this.state.snackbarMessage}</span>}
			/>		
			</div>
			)
	}

}
Login.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Login);
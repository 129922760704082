import React, { Component } from 'react';
import QrReader from 'react-qr-reader';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

class VisitorScan extends Component {

	constructor(props){
		super(props);
		this.state ={
			qrDialog : this.props.qrDialog,
			delay: 500,
			data : null,
			callback: this.props.callback ? this.props.callback : null
		}
		this.handleError =this.handleError.bind(this);
		this.handleScan =this.handleScan.bind(this);
		this.handleCameraClose =this.handleCameraClose.bind(this);
		this.sendVisitorQR =this.sendVisitorQR.bind(this);
	}

	handleCameraClose(){
		this.setState({
			qrDialog : false
		})
	}
	componentWillReceiveProps(nextProps) {
		let copiedStates = Object.assign({},this.state);
		copiedStates.qrDialog = nextProps.qrDialog;
		this.setState(copiedStates);
	}
	handleError(err){
		console.error(err)
	}
	handleScan(data){
		if(data) {	
			this.setState({
				data : data
			});
		}
	}
	sendVisitorQR(){
		this.state.callback(this.state.data);
	}
	render(){

		return(
			<Dialog
			size={'md'}
			disableBackdropClick={true}
			open={this.state.qrDialog}
			onClose={this.handleCameraClose}
			style={{
				overflowY: 'hidden'
			}}
			>
			<DialogTitle id={"dialog-title"}>{"Scan your id card"}</DialogTitle>
			<DialogContent style={{
				overflowY: 'hidden'
			}}>
			<p>{this.state.data}</p>
			<QrReader
			style={{
				height: 500,
				width: 500
			}}
			className='qrCodeScanner'
			resolution={1080}
			delay={this.state.delay}
			onError={this.handleError}
			onScan={this.handleScan}
			/>
			</DialogContent>
			<DialogActions>
			<Button variant="raised" color='primary' style={{
				margin: 15
			}} disabled={!Boolean(this.state.data)} onClick={this.sendVisitorQR}>
			Scan
			</Button>
			</DialogActions>
			</Dialog>
			)
	}

}
export default VisitorScan;
import React, { Component } from 'react';
import  { Redirect } from 'react-router-dom'
import QrReader from 'react-qr-scanner';
import { Row, Col } from 'reactstrap';
import ReactAudioPlayer from 'react-audio-player';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import * as constants from '../config/constants';
import {geolocated} from 'react-geolocated';

class Login extends Component {
	constructor(props){
		super();
		this.state = {
			delay: 500,
			result: 'Scan for the attendance',
			response: '',
			buttonDisabled: true,
			employeeId: '',
			latitude: null,
			longitude: null,
			employeeDetails: '',
			redirect: false
		}
		this.handleScan = this.handleScan.bind(this);
		this.handleError = this.handleError.bind(this);
		this.saveAttendance = this.saveAttendance.bind(this);
		this.clearData = this.clearData.bind(this);
	}
	componentWillReceiveProps(nextProps) {
		let copiedStates = Object.assign({}, this.state);
		if(nextProps.isGeolocationAvailable && nextProps.isGeolocationEnabled && nextProps.coords) {
			copiedStates.latitude = nextProps.coords.latitude;
			copiedStates.longitude = nextProps.coords.longitude;
		}
		this.setState(copiedStates);
	}
	clearData() {
		let copiedStates = {
			delay: 500,
			result: 'Scan for the attendance',
			response: '',
			buttonDisabled: true,
			employeeId: '',
			employeeDetails: ''
		};
		this.setState(copiedStates);
	}
	handleScan(data){
		let that = this;
		if(data && this.state.employeeId === '') {
			let copiedStates = Object.assign({},this.state);
			var emp_Id =data.split(':');
			copiedStates.result = "Hey! Ensure your employee id is ";
			copiedStates.employeeId = emp_Id[1];
			axios.get(constants.API_BASE_URL + "/associate/findOne/" +  emp_Id[1])
			.then(function (response) {
				copiedStates.employeeDetails = response.data.details;
				that.setState(copiedStates);
			});
			document.getElementById("audioBeep").play();
			copiedStates.buttonDisabled = false;
			copiedStates.response = '';
			this.setState(copiedStates);
		}
	}
	handleError(err){
		console.error(err)
	}
	saveAttendance() {
		let copiedStates = Object.assign({},this.state);
		let that=this;
		copiedStates.response = '';
		copiedStates.buttonDisabled = true;
		let request = {
			latitude: copiedStates.latitude,
			longitude: copiedStates.longitude,
		}
		axios.post(constants.API_BASE_URL + "/associate/login/" +  this.state.employeeId, request)
		.then(function (response) {
			if(response.data.code === 200){
				copiedStates.response = that.state.employeeId+ " logged in successfully";
				copiedStates.result = "Scan for the attendance";
				let audioFiles = ["GDA", "GMHND", "HMWH"];
				let audioFileToPlay = audioFiles[Math.round(Math.random() * (audioFiles.length - 1))];
				document.getElementById("audioMessage").setAttribute("src", "audio/" + audioFileToPlay + ".mp3");
				document.getElementById("audioMessage").play();
				setTimeout(function(){
					copiedStates.redirect = true;
					that.setState(copiedStates);
				}, 4000);
			}
			else {
				copiedStates.response = "Error. Please login again!"
				copiedStates.result = "Try scanning again";
				setTimeout(function(){
					that.clearData();
				}, 2000);
			}
			that.setState(copiedStates);
		})
		.catch(function (error) {
			copiedStates.response = "Error. Please login again!"
			copiedStates.result = "Try scanning again";
			copiedStates.employeeId = '';
			copiedStates.buttonDisabled = true;
			setTimeout(function(){
				that.clearData();
			}, 2000);
			that.setState(copiedStates);
		});
		that.setState(copiedStates);
	}
	render() {
		const previewStyle = {
			width: '100%',
		}
		const { redirect } = this.state;
		if (redirect) {
			return <Redirect to="/" />;
		}
		return(
			<div style={{
				padding: 15
			}}>
			<Row>
			<Col md={8}>
			<div>	
			<QrReader
			className='qrCodeScanner'
			resolution={144}
			maxImageSize={1000}
			facingMode="rear"
			delay={this.state.delay}
			onError={this.handleError}
			onScan={this.handleScan}
			style={previewStyle}
			/>
			</div>
			</Col>
			<Col md={4}>
			<br />
			<br />
			<br />
			<p>{this.state.response}</p>
			{this.state.employeeDetails && (
				<p>Welcome <b>{this.state.employeeDetails.name}</b>. Nice to meet you!</p>
				)}

			<br />
			<br />
			<br />
			<br />
			<Button color='primary' variant="raised" style={{
				margin: 15
			}} disabled={this.state.buttonDisabled} onClick={this.saveAttendance}>
			Login
			</Button>
			<Button color='primary' variant="raised" style={{
				margin: 15
			}} disabled={this.state.buttonDisabled} onClick={this.clearData}>
			Clear
			</Button>
			</Col>
			</Row>
			<Row>
			<Col md={12}>
			<ReactAudioPlayer style={{visibility : 'hidden'}}
			id={"audioMessage"}
			src=""
			/>
			<ReactAudioPlayer style={{visibility : 'hidden'}}
			id={"audioBeep"}
			src="beep-01a.mp3"
			controls
			/>
			</Col>
			</Row>
			</div>
			)
	}
}
export default geolocated({
	positionOptions: {
		enableHighAccuracy: true,
	},
	userDecisionTimeout: 5000,
})(Login);
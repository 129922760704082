import React, { Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import NewsFeed from "./newsFeed";
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
	root: {
		backgroundColor: theme.palette.background.paper,
		width: 500,
	},
});

class ScreenSaver extends Component {
	render() {
		return (
			<div style={{
				position: 'fixed',
				top: 0,
				left: 0,
				width: '100%',
				height: '100vh'
			}} >
			<NewsFeed />
			<Typography className={"touchMeBtn"} style={{
				color: '#EDF2F4',
				position: 'absolute',
				right: 30,
				bottom: 30,
				fontSize: '20px',
				backgroundColor: '#2B2D42',
				border: '1px solid #EDF2F4',
				padding: '10px',
			}}>Touch me</Typography>
			</div>
			);
	}
}

export default withStyles(styles)(ScreenSaver);
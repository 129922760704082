import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import * as constants from '../config/constants';
import axios from 'axios';
import Typography from '@material-ui/core/Typography';
import Datatable from 'react-bs-datatable';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import Divider from '@material-ui/core/Divider';
import moment from 'moment';
import 'font-awesome/css/font-awesome.css';
import parseMs from 'parse-ms';
import { CSVLink, CSVDownload } from "react-csv";
import { DateRangePicker } from 'react-date-range';
import GoogleMapReact from 'google-map-react';

function formatParsedMs(parsedMs) {
	parsedMs = parseMs(parsedMs);
	let ms = '';
	if(parsedMs.hours)
		ms += parsedMs.hours + ' hours ';
	if(parsedMs.minutes)
		ms += parsedMs.minutes + ' mins ';
	if(parsedMs.seconds)
		ms += parsedMs.seconds + ' secs ';
	return ms;
}
const onSortFunction = {
	timeSpent(columnValue) {
		columnValue = columnValue.split(' ');
		let totalSeconds = 0;
		if(columnValue[1] === "hours") {

			totalSeconds = parseInt(columnValue[0]) * 3600;
			totalSeconds += parseInt(columnValue[2]) * 60;
			totalSeconds += parseInt(columnValue[4]);

		} else if(columnValue[1] === "mins") {
			
			totalSeconds = parseInt(columnValue[0]) * 60;
			totalSeconds += parseInt(columnValue[2]);

		} else if(columnValue[1] === "secs") {
			totalSeconds = parseInt(columnValue[0]);
		}
		return totalSeconds;
	},
	logInTime(columnValue) {
		return moment(columnValue, 'DD-MM-YY, h:mm a').valueOf();
	},
	logOutTime(columnValue) {
		return moment(columnValue, 'DD-MM-YY, h:mm a').valueOf();
	},
};

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing.unit * 3,
		overflowX: 'auto',
	},
	heading: {
		fontSize: theme.typography.pxToRem(10),
		fontWeight: theme.typography.fontWeightRegular,
	},
	listItem: {
		padding: '5px'
	},
	instructions: {
		marginTop: theme.spacing.unit,
		marginBottom: theme.spacing.unit,
	}
});

const header = [
{ title: 'Id', prop: 'associateId', sortable: true, filterable: true },
{ title: 'Name', prop: 'associateName', sortable: true, filterable: true },
{ title: 'Email', prop: 'associateEmail', sortable: true, filterable: true },
{ title: 'Type', prop: 'type', sortable: true, filterable: true },
{ title: 'Date', prop: 'date', sortable: true, filterable: true },
{ title: 'Time', prop: 'time', sortable:true, filterable: true },
{ title: 'View Map', prop: 'viewMap', sortable: false, filterable: false },
];

const customLabels = {
	first: '<<',
	last: '>>',
	prev: '<',
	next: '>',
	show: 'Display',
	entries: 'rows',
	noResults: 'There is no data to be displayed',
};

function getSteps() {
	return ['Filters', 'Download Report'];
}

function getStepContent(step) {
	switch (step) {
		case 0:
		return 'Select campaign settings...';
		case 1:
		return 'What is an ad group anyways?';
		default:
		return 'Unknown step';
	}
}

const AnyReactComponent = ({ text }) => (
	<div style={{
		color: 'white', 
		fontSize: '8px', 
		background: 'black',
		padding: '1px 1px',
		display: 'inline-flex',
		textAlign: 'center',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: '100%',
		transform: 'translate(-50%, -50%)'
	}}>
	{text}
	</div>
	);

class AssociateLogins extends Component {
	constructor() {
		super();
		this.state = {
			activeStep: 0,
			downloadStatus: false,
			details: [],
			selectionRange: {
				startDate: new Date(),
				endDate: new Date(),
				key: 'selection',
			}
		}
		this.handleSelect = this.handleSelect.bind(this);
		this.handleReset = this.handleReset.bind(this);
		this.handleNext = this.handleNext.bind(this);
		this.handleBack = this.handleBack.bind(this);
	}
	handleReset() {
		let copiedStates = Object.assign({}, this.state);
		copiedStates.activeStep = 0;
		this.setState(copiedStates);
	}
	handleNext() {
		let copiedStates = Object.assign({}, this.state);
		let that = this;
		if(copiedStates.activeStep === 1) {
			copiedStates.activeStep = copiedStates.activeStep + 0;
		} else {
			copiedStates.activeStep = copiedStates.activeStep + 1;
			console.log("ranges", this.state.selectionRange);
			let e = moment(this.state.selectionRange.endDate).unix() *  1000;
			let s = moment(this.state.selectionRange.startDate).unix() *  1000;
			console.log("unix e", moment(e).unix());
			console.log("unix s", moment(s).unix());
			console.log("url", constants.API_BASE_URL + '/associate/findLogins?s=' + s + "&e=" + e);
			axios.get(constants.API_BASE_URL + '/associate/findLogins?s=' + s + "&e=" + e)
			.then(function(response){
				copiedStates.details = response.data.details;
				that.setState(copiedStates);
			})
			.catch(function(error){
			})
		}
		this.setState(copiedStates);
	}
	handleBack() {
		let copiedStates = Object.assign({}, this.state);
		copiedStates.activeStep = copiedStates.activeStep - 1;
		this.setState(copiedStates);
	}
	getStepContent(step) {
		switch (step) {
			case 0:
			return 'Select campaign settings...';
			case 1:
			return 'What is an ad group anyways?';
			default:
			return 'Unknown step';
		}
	}
	componentDidMount() {
		moment.locale();
		let copiedStates = Object.assign({}, this.state);
		var that = this;
		/*axios.get(constants.API_BASE_URL + '/associate/findLogins')
		.then(function(response){
			copiedStates.details = response.data.details;
			that.setState(copiedStates);
		})
		.catch(function(error){
		})*/
	}
	handleSelect(ranges){
		console.log(ranges);
		let copiedStates = Object.assign({}, this.state);
		copiedStates.selectionRange = ranges.selection;
		this.setState(copiedStates);
	}
	render(){
		let data = this.state.details;
		console.log("details", this.props);
		let bodyArr =[];
		let csvData =[[
		"Assocaite ID",
		"Assocaite Name",
		"Email",
		"Type",
		"Date",
		"Time",
		"Latitude",
		"Longitude",
		]];
		let mapLink = "https://www.google.co.in/maps/@";

		data.map(n =>{
			bodyArr.push({
				associateId: n.associateId,
				associateName: n.associate.name,
				associateEmail: n.associate.email,
				type: n.type,
				date: n.date,
				time: moment(n.time).format('DD-MM-YY, h:mm a'),
				viewMap: <p><a href={"https://www.latlong.net/c/?lat=" + n.latitude + "&long=" + n.longitude + " "} target="_blank">view map</a></p>
			});
			csvData.push([
				n.associateId,
				n.associate.name,
				n.associate.email,
				n.type,
				n.date,
				moment(n.time).format('DD-MM-YY, h:mm a'),
				n.latitude,
				n.longitude
				]);
		});

		const steps = getSteps();
		const activeStep = this.state.activeStep;

		return (
			<Card>
			<CardHeader
			style={{
				textAlign: 'left'
			}}
			avatar={
				<Avatar aria-label="Ethnus" src="justLogo.jpg" style={{
					width: 50,
					height: 50
				}} />
			}
			title={<Typography style={{
				fontWeight: 700,
				textTransform: 'uppercase',
				fontSize: '25px'
			}} >Welcome to Ethnus</Typography>}
			/>
			<Divider />
			<CardContent style={{
				minHeight: 500,
				maxHeight: 500,
				overflowY: 'auto' 
			}} >

			<Stepper activeStep={this.state.activeStep}>
			{steps.map((label, index) => {
				const stepProps = {};
				const labelProps = {};
				return (
					<Step key={label} {...stepProps}>
					<StepLabel {...labelProps}>{label}</StepLabel>
					</Step>
					);
			})}
			</Stepper>

			<div>
			{activeStep === steps.length ? (
				<div>
				<Typography>
				All steps completed - you&apos;re finished
				</Typography>
				<Button onClick={this.handleReset}>
				Reset
				</Button>
				</div>
				) : (
				<div>
				<Typography component="div">{
					activeStep === 0 ? (
						<div>
						<DateRangePicker
						ranges={[this.state.selectionRange]}
						onChange={this.handleSelect}
						/>
						</div>
						) : (
						<div class="dtcols">
						<CSVLink data={csvData} filename={"my-file.csv"}>Download me</CSVLink>
						<Datatable
						tableHeader={header}
						tableBody={bodyArr}
						keyName="userTable"
						tableClass="striped hover responsive"
						rowsPerPage={50}
						rowsPerPageOption={[50,100,150,200]}
						initialSort={
							{prop: "date", isDescending: true}
						}
						labels={customLabels}
						onSort={onSortFunction}
						/>
						</div>
						)
					}</Typography>
					<div>
					<Button disabled={activeStep === 0} onClick={this.handleBack}>
					Back
					</Button>
					<Button
					disabled={activeStep === 1 && this.state.downloadStatus === false}
					variant="contained"
					color="primary"
					onClick={this.handleNext}
					>
					{activeStep === steps.length - 1 ? 'Download' : 'Next'}
					</Button>
					</div>
					</div>
					)
			}
			</div>
			</CardContent>
			</Card>
			);
	}
}

AssociateLogins.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AssociateLogins);

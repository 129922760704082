import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Divider from '@material-ui/core/Divider';
import Login from './login';
import Logout from './logout';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';


const styles = theme => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	textField: {
		marginLeft: theme.spacing.unit,
		marginRight: theme.spacing.unit,
		width: 200,
	},
});

function TabContainer(props) {
	return (
		<Typography component="div">
		{props.children}
		</Typography>
		);
}

TabContainer.propTypes = {
	children: PropTypes.node.isRequired,
};

class Scan extends Component {
	constructor(props){
		super();
		this.state ={
			tabValue : 0

		}
		this.handleTabChange = this.handleTabChange.bind(this);
	}
	handleTabChange = (event, value) => {
		this.setState({
			tabValue: value
		});
	}

	render(){
		return(
			<Row>
			<Col className={"transparent-bg"} md={{
				size: 10,
				offset: 1
			}} >
			<br />
			<Card>
			<CardHeader
			style={{
				textAlign: 'left'
			}}
			avatar={
				<Avatar aria-label="Ethnus" src="justLogo.jpg" style={{
					width: 50,
					height: 50
				}} />
			}
			title={<b>Hello Associate!</b>}
			subheader={this.state.tabValue === 0 ? (<Typography style={{
				fontSize: '16px'
			}}>Please scan your id card to login</Typography>) : (<Typography style={{
				fontSize: '16px'
			}}>Please scan your id card to logout</Typography>)}
			/>
			<Divider />
			<CardContent style={{
				paddingTop: 0
			}}>
			<Tabs 
			fullWidth
			centered
			value={this.state.tabValue} onChange={this.handleTabChange}>
			<Tab label="Login" />
			<Tab label="Logout" />
			</Tabs>
			
			<Divider />
			{this.state.tabValue === 0 && <TabContainer><Login /></TabContainer>}
			{this.state.tabValue === 1 && <TabContainer><Logout /></TabContainer>}

			</CardContent>
			</Card>
			</Col>
			</Row>
			)
	}
}
Scan.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Scan);
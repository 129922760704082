import React, { Component } from 'react';
import QrReader from 'react-qr-reader';
import * as constants from '../config/constants';
import axios from 'axios';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import {Row, Col } from 'reactstrap';
import Snackbar from '@material-ui/core/Snackbar';

class Exit extends Component {
	constructor(props){
		super();
		this.state ={
			delay: 500,
			data : null,
			snackbar : false,
			snackbarMessage : '',
			disable : true

		}
		this.handleError = this.handleError.bind(this);
		this.handleScan = this.handleScan.bind(this);
		this.handleExit = this.handleExit.bind(this);
		this.closeSnackBar = this.closeSnackBar.bind(this);
		this.clearData = this.clearData.bind(this);
	}

	handleError(err){
		console.error(err)
	}
	handleScan(data){
		let copiedStates = Object.assign({},this.state);
		if(data){
			console.log('data',data);
			copiedStates.disable = false;
			copiedStates.data = data;
			this.setState(copiedStates);
		}
	}
	handleExit(){
		let copiedStates = Object.assign({},this.state);
		var that = this; 
		let request = {
			status: "out"
		};
		axios.put(constants.API_BASE_URL + '/visitor/' + that.state.data, request)
		.then(function (response) {
			console.log('response',response.data.details);
			copiedStates.disable = true
			copiedStates.snackbar = true;
			copiedStates.snackbarMessage = response.data.message;
			that.setState(copiedStates);
		})
		.catch(function (error) {
		});
	}
	closeSnackBar(){
		this.setState({
			snackbar : false
		})
	}
	clearData(){
		let copiedStates = Object.assign({},this.state);
		copiedStates.data = null;
		copiedStates.disable = true;
		this.setState(copiedStates);
	}
	render(){
		return(
			<div style={{
				padding: 15
			}}>
			<Row style={{
				padding: 15
			}} >
			<Col md={{
				offset: 3,
				size: 6
			}}>
			<Card style={{
				padding: 15
			}}>
			<CardHeader
			style={{
				textAlign: 'left'
			}}
			avatar={
				<Avatar aria-label="Ethnus" src="justLogo.jpg" style={{
					width: 100,
					height: 100
				}} />
			}
			title={<Typography style={{
				fontWeight: 700,
				textTransform: 'uppercase',
				fontSize: '25px'
			}} >Welcome to Ethnus</Typography>}
			subheader="Please scan to logout"
			/>
			<Divider />
			<CardContent style={{
				paddingTop: 0,

			}}>
			<div style={{
				height: '50vh',
				overflowY: 'auto'
			}}>
			<QrReader
			style = {{ 
				height : 200,
				width : '100%'
			}}
			className='qrCodeScanner'
			resolution={1080}
			delay={this.state.delay}
			onError={this.handleError}
			onScan={this.handleScan}
			/>
			</div>
			</CardContent>
			<div style={{
				textAlign: 'right'
			}}>
			<Button variant="raised" color='primary' style={{
				marginRight: 15,
			}} disabled={this.state.disable} onClick={this.clearData}>
			Clear
			</Button>
			<Button variant="raised" color='primary' style={{
				marginLeft: 15,
			}} disabled={this.state.disable} onClick={this.handleExit}>
			Exit
			</Button>
			</div>
			</Card>
			</Col>
			</Row>
			<Snackbar
			open={this.state.snackbar}
			onClose={this.closeSnackBar}
			autoHideDuration={1000}
			TransitionComponent={this.state.Transition}
			ContentProps={{
				'aria-describedby': 'message-id',
			}}
			message={<span id="message-id">{this.state.snackbarMessage}</span>}
			/>
			</div>

			)

	}
}
export default Exit;

/*import React, { Component } from 'react';
import {Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import axios from 'axios';
import * as constants from '../config/constants';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Snackbar from '@material-ui/core/Snackbar';

const styles = theme => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	textField: {
		marginLeft: theme.spacing.unit,
		marginRight: theme.spacing.unit,
		width: 200,
	},
});
class Exit extends Component {
	constructor(props){
		super();
		this.state ={
			visitors : [],
			name: '',
			id : '',
			snackbar : false,
			snackbarMessage : ''

		}
		this.handleInputChange = this.handleInputChange.bind(this); 
		this.logOutFromEthnus = this.logOutFromEthnus.bind(this);
		this.closeSnackBar = this.closeSnackBar.bind(this);
	}
	componentDidMount() {
		let copiedStates = Object.assign({},this.state);
		let that = this;
		axios.get(constants.API_BASE_URL + "/visitor/loggedIn")
		.then(function(response){
			copiedStates.visitors = response.data.details;
			that.setState(copiedStates);
		})
		.catch(function(error){
			alert(error);
		})
	}
	closeSnackBar() {
		this.setState({snackbar: false});
	}
	logOutFromEthnus() {
		let copiedStates = Object.assign({},this.state);
		var that = this; 
		let request = {
			status: "out"
		};
		axios.put(constants.API_BASE_URL + '/visitor/' + that.state.id, request)
		.then(function (response) {
			if(typeof response.data.details !== "undefined") {
				let currentVisitors = [];
				for(let i = 0; i < copiedStates.visitors.length; i++) {
					if(response.data.details.id !== copiedStates.visitors[i].id) {
						currentVisitors.push(copiedStates.visitors[i]);
					}
				}
				copiedStates.snackbar = true;
				copiedStates.snackbarMessage = response.data.message;
				copiedStates.id = '';
				copiedStates.name = '';
				copiedStates.visitors = currentVisitors;
				that.setState(copiedStates);
			}
		})
		.catch(function (error) {
		});
	}
	handleInputChange(event) {
		let copiedStates = Object.assign({},this.state);
		copiedStates[event.target.name] = event.target.value;
		copiedStates.id = event.target.value;
		this.setState(copiedStates);
	}
	
	render(){
		var visitorsArr = this.state.visitors;
		var visitorsComponent = [];
		for(var i=0;i<visitorsArr.length;i++){
			visitorsComponent.push(
				<MenuItem value={visitorsArr[i].id}>{visitorsArr[i].name + " - " + visitorsArr[i].email}</MenuItem>
				);
		}

		const { classes } = this.props;
		return(
			<div style={{
				padding: 15
			}}>
			<Paper style={{
				padding: 15
			}} >
			<Row style={{
				padding: 15
			}} >
			<Col md={12}>
			<Typography>Select the visitor to mark his exit</Typography>
			<Select
			fullWidth
			value={this.state.name}
			onChange={this.handleInputChange}
			inputProps={{
				name: 'name',
				id: 'name'
			}}
			>
			{visitorsComponent}
			</Select>
			<br />
			<br />
			<Button  style={{
				float: 'right'
			}} variant="raised" color="primary" disabled={this.state.name === ''} onClick={this.logOutFromEthnus}>
			Exit
			</Button>
			</Col>
			</Row>
			</Paper>
			<Snackbar
			open={this.state.snackbar}
			onClose={this.closeSnackBar}
			autoHideDuration={1000}
			TransitionComponent={this.state.Transition}
			ContentProps={{
				'aria-describedby': 'message-id',
			}}
			message={<span id="message-id">{this.state.snackbarMessage}</span>}
			/>
			</div>
			)
	}
}
Exit.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Exit);
*/
import React, { Component } from 'react';
import {Row, Col } from 'reactstrap';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import CardHeader from '@material-ui/core/CardHeader';
import Snackbar from '@material-ui/core/Snackbar';
import axios from 'axios';
import * as constants from '../config/constants';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import Select from 'react-select';
import NoSsr from '@material-ui/core/NoSsr';
import MenuItem from '@material-ui/core/MenuItem';
import 'react-html5-camera-photo/build/css/index.css';
import AWS from 'aws-sdk';
import Face from './face';
import ReactAudioPlayer from 'react-audio-player';
import VisitorScan from './visitorqrScan';

const config = {
	region: 'ap-south-1',
	accessKeyId: 'AKIAJWQPMA7BDXWCH4HA',
	secretAccessKey: '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',
}
AWS.config.update(config);

const styles = theme => ({
	root: {
		flexGrow: 1,
		height: 250,
	},
	input: {
		display: 'flex',
		padding: 0,
	},
	valueContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		flex: 1,
		alignItems: 'center',
	},
	chip: {
		margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
	},
	chipFocused: {
		backgroundColor: emphasize(
			theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
			0.08,
			),
	},
	noOptionsMessage: {
		padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
	},
	singleValue: {
		fontSize: 16,
	},
	placeholder: {
		position: 'absolute',
		left: 2,
		fontSize: 16,
	},
	paper: {
		marginTop: theme.spacing.unit,
	},
	divider: {
		height: theme.spacing.unit * 2,
	},
});

function NoOptionsMessage(props) {
	return (
		<Typography
		color="textSecondary"
		className={props.selectProps.classes.noOptionsMessage}
		{...props.innerProps}
		>
		{props.children}
		</Typography>
		);
}

function inputComponent({ inputRef, ...props }) {
	return <div ref={inputRef} {...props} />;
}

function Control(props) {
	return (
		<TextField
		fullWidth
		InputProps={{
			inputComponent,
			inputProps: {
				className: props.selectProps.classes.input,
				inputRef: props.innerRef,
				children: props.children,
				...props.innerProps,
			},
		}}
		{...props.selectProps.textFieldProps}
		/>
		);
}

function Option(props) {
	return (
		<MenuItem
		buttonRef={props.innerRef}
		selected={props.isFocused}
		component="div"
		style={{
			fontWeight: props.isSelected ? 500 : 400,
		}}
		{...props.innerProps}
		>
		{props.children}
		</MenuItem>
		);
}

function Placeholder(props) {
	return (
		<Typography
		color="textSecondary"
		className={props.selectProps.classes.placeholder}
		{...props.innerProps}
		>
		{props.children}
		</Typography>
		);
}

function SingleValue(props) {
	return (
		<Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
		{props.children}
		</Typography>
		);
}

function ValueContainer(props) {
	return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}


function Menu(props) {
	return (
		<Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
		{props.children}
		</Paper>
		);
}

const components = {
	Option,
	Control,
	NoOptionsMessage,
	Placeholder,
	SingleValue,
	ValueContainer,
	Menu,
};

class Entry extends Component {
	constructor(props){
		super();
		this.state = {
			name : '',
			email : '',
			mobile : '',
			purpose : '',
			whom : '',
			snackbar : false,
			snackBarMessage : '',
			dialogOpen : false,
			inputValue : '',
			single : null,
			selectedItem: [],
			thankYouMessage : false,
			cameraDialog: false,
			photoLocation: null,
			qrDialog : false,
			qrCode: null
		}
		this.handleInputChange = this.handleInputChange.bind(this);
		this.loginIntoEthnus = this.loginIntoEthnus.bind(this);
		this.closeSnackBar = this.closeSnackBar.bind(this);
		this.validate = this.validate.bind(this);
		this.handleDialogOpen = this.handleDialogOpen.bind(this);
		this.handleCloseDialog = this.handleCloseDialog.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.validateMeet = this.validateMeet.bind(this);
		this.handleCameraOpen = this.handleCameraOpen.bind(this);
		this.handleCloseCameraDialog = this.handleCloseCameraDialog.bind(this);
		this.onTakePhoto = this.onTakePhoto.bind(this);
		this.handleQRDialog =this.handleQRDialog.bind(this);
		this.qrCallBack =this.qrCallBack.bind(this);
	}
	qrCallBack(data) {
		let copiedStates = Object.assign({}, this.state);
		copiedStates.qrCode = data;
		copiedStates.qrDialog = false;
		copiedStates.dialogOpen = true;
		this.setState(copiedStates);
	}
	onTakePhoto (photoLocation) {
		let that = this;
		if(photoLocation) {
			let nicePhotoAudio = ["WWAB", "YRLG", "TAGC", "TFNP", "IAIGS"];
			let randomAudio = nicePhotoAudio[Math.round(Math.random() * (nicePhotoAudio.length - 1))];
			let copiedStates = Object.assign({}, this.state);
			let audio = "audio/" + randomAudio + ".mp3";
			copiedStates.photoLocation = photoLocation;
			copiedStates.cameraDialog = false;
			copiedStates.qrDialog = true;
			that.setState(copiedStates);
			let audioElement = document.getElementById("audioMessage");
			audioElement.setAttribute("src", audio);
			audioElement.play();
			audioElement.onended = function() {
				audioElement.setAttribute("src", "audio/SVIC.mp3");
				audioElement.play();
				audioElement.onended = null;
			}
		}
	}
	componentWillUnmount () {
		this.tracker && this.tracker.removeAllListeners();
	}
	componentDidMount() {
		let that = this;
		axios.get(constants.API_BASE_URL + '/associate/find')
		.then(function(response) {
			var selectedItem = [];
			for (var i =0 ;i<response.data.details.length;i++) {
				selectedItem.push({label : response.data.details[i].name, value: response.data.details[i].employeeId});
			}
			that.setState({
				selectedItem : selectedItem
			});
		}) 
		.catch(function (error) {
		});
	}
	handleChange = name => value => {
		this.setState({
			[name]: value,
		});
	};
	handleInputChange(event) {
		let copiedStates = Object.assign({},this.state);
		copiedStates[event.target.name] = event.target.value;
		this.setState(
			copiedStates
			);
	}
	closeSnackBar(){
		this.setState({
			snackbar : false
		})
	}
	handleDialogOpen(){
		if(!this.validate()) {
			return;
		}
		let copiedStates = Object.assign({},this.state);
		copiedStates.dialogOpen = true;
		copiedStates.cameraDialog = false;
		this.setState(copiedStates);
	}
	handleCameraOpen(){
		if(!this.validate()) {
			return;
		}
		this.setState({
			cameraDialog : true
		})
	}
	handleCloseCameraDialog(){
		this.setState({
			cameraDialog :  false
		})
	}
	handleCloseDialog(){
		this.setState({
			dialogOpen : false
		})
	}
	handleQRDialog(){
		this.setState({
			qrDialog : true
		})	
	}

	validate() {
		let copiedStates = Object.assign({},this.state);
		let isvalid = false;
		let phoneno = /^[987]\d{9}$/;
		if(!copiedStates.name) {
			copiedStates.snackBarMessage ="Fill in your name";
			isvalid = true;
		}

		else if(!copiedStates.email) {
			copiedStates.snackBarMessage ="Please enter valid email address";
			isvalid = true;
		}
		else if(!copiedStates.mobile || !copiedStates.mobile.match(phoneno)) {
			copiedStates.snackBarMessage ="Please enter 10 digit mobile number";
			isvalid = true;
		}
		else if(!copiedStates.purpose) {
			copiedStates.snackBarMessage ="Please enter purpose of your visit";
			isvalid = true;
		}

		copiedStates.snackbar = isvalid;
		this.setState(copiedStates);
		return !isvalid;
	}
	validateMeet(){
		let copiedStates = Object.assign({},this.state);
		let isvalid = false;
		if(!copiedStates.single || !copiedStates.single.value) {
			copiedStates.snackBarMessage ="Please select the contact person";
			isvalid = true;
		}
		copiedStates.snackbar = isvalid;
		this.setState(copiedStates);
		return !isvalid;
	}
	loginIntoEthnus(event) {
		event.preventDefault();
		let that = this;
		let copiedStates = Object.assign({},this.state);
		if(!this.validateMeet()) {
			return;
		}
		let request = {
			name : this.state.name,
			email : this.state.email,
			mobile : this.state.mobile,
			purpose : this.state.purpose,
			whom: this.state.single.value,
			picture: this.state.photoLocation,
			qrcode : this.state.qrCode
		}
		axios.post(constants.API_BASE_URL + "/visitor", request)
		.then(function (response) {
			copiedStates.snackbar = true;
			copiedStates.cameraDialog = false;
			copiedStates.snackBarMessage = "Email has been sent to the contact person";
			copiedStates.thankYouMessage = "Thank you for visiting Ethnus! Concerned person will get back to you";
			setTimeout(function () {
				copiedStates = {
					name : '',
					email : '',
					mobile : '',
					purpose : '',
					whom : '',
					snackbar : false,
					snackBarMessage : '',
					dialogOpen : false,
					inputValue : '',
					single : null,
					selectedItem: [],
					thankYouMessage : false,
					cameraDialog: false,
					photoLocation: null,
					qrCode: null
				}
				that.setState(copiedStates);
				window.location.href = "/";
			}, 5000);
			copiedStates.dialogOpen = false
			that.setState(copiedStates);

		})
		.catch(function (error) {
		});
		that.setState(copiedStates);
	}
	render(){
		const { classes, theme } = this.props;
		const selectStyles = {
			input: base => ({
				...base,
				color: theme.palette.text.primary,
			}),
		};
		return(
			<div className={"transparent-bg"} style={{
				padding: 15
			}}>
			<Row style={{
				padding: 15
			}} >
			<Col md={{
				offset: 3,
				size: 6
			}}>
			<Card style={{
				padding: 15
			}}>
			<CardHeader
			style={{
				textAlign: 'left'
			}}
			avatar={
				<Avatar aria-label="Ethnus" src="justLogo.jpg" style={{
					width: 100,
					height: 100
				}} />
			}
			title={<Typography style={{
				fontWeight: 700,
				textTransform: 'uppercase',
				fontSize: '25px'
			}} >Welcome to Ethnus</Typography>}
			subheader="Please leave your details here"
			/>
			<Divider />
			<CardContent style={{
				paddingTop: 0
			}}>
			<div style={{
				display: Boolean(this.state.thankYouMessage) === true ? 'none' : 'block'
			}} >
			<TextField
			fullWidth
			id="name"
			name="name"
			label="Name"
			value={this.state.name}
			onChange={this.handleInputChange}
			margin="normal"
			/>
			<TextField
			fullWidth
			name="email"
			label="Email address"
			value={this.state.email}
			onChange={this.handleInputChange}
			margin="normal"
			/>
			<TextField
			fullWidth
			name="mobile"	
			label="Contact number"
			value={this.state.mobile}
			onChange={this.handleInputChange}
			margin="normal"
			/>
			<TextField
			fullWidth
			name="purpose"	
			label="Purpose of your visit"
			value={this.state.purpose}
			onChange={this.handleInputChange}
			margin="normal"
			/>
			<Button style={{float: 'right'}} variant="raised" color="primary" onClick={this.handleCameraOpen}>
			Next
			</Button>
			</div>
			
			<div id="mydiv">
			<br />
			<Typography variant="subheader" gutterBottom>
			{this.state.thankYouMessage}
			</Typography>
			</div>

			</CardContent>
			</Card>
			</Col>
			</Row>
			<Snackbar
			open={this.state.snackbar}
			onClose={this.closeSnackBar}
			autoHideDuration={1000}
			TransitionComponent={this.state.Transition}
			ContentProps={{
				'aria-describedby': 'message-id',
			}}
			message={<span id="message-id">{this.state.snackBarMessage}</span>}
			/>
			<Dialog
			disableBackdropClick={true}
			maxWidth={"md"}
			style={{
				overflowY: 'hidden'
			}}
			open={this.state.cameraDialog}
			onClose={this.handleCloseCameraDialog}
			>
			<DialogTitle>Click your photo</DialogTitle>
			<DialogContent style={{
				overflowY: 'hidden'
			}}>
			<Face name={this.state.name} callback={this.onTakePhoto} />
			</DialogContent>
			<DialogActions>
			<Button color="primary" variant="raised" onClick={this.handleQRDialog}
			disabled={!Boolean(this.state.photoLocation)} >
			Next
			</Button>

			</DialogActions>
			</Dialog>

			<Dialog
			disableBackdropClick={true}
			open={this.state.dialogOpen}
			onClose={this.handleCloseDialog}
			>
			<DialogTitle>Whom do you want to meet ?</DialogTitle>
			<DialogContent>
			<NoSsr>
			<Select
			classes={classes}
			styles={selectStyles}
			options={this.state.selectedItem}
			components={components}
			value={this.state.single}
			onChange={this.handleChange('single')}
			placeholder="Select contact person from the list"
			/>
			</NoSsr>
			</DialogContent>
			<DialogActions>
			<Button color="primary" onClick={this.loginIntoEthnus}>
			Meet
			</Button>
			</DialogActions>
			</Dialog>
			<ReactAudioPlayer style={{visibility : 'hidden'}}
			id={"audioMessage"}
			src=""
			/>
			<VisitorScan 
			qrDialog = {this.state.qrDialog}
			callback={this.qrCallBack}
			/>
			</div>
			)
	}
}
Entry.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
};
export default withStyles(styles, { withTheme: true })(Entry);
import React, { Component} from 'react';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';
let Parser = require('rss-parser');

const styles = theme => ({
	root: {
		backgroundColor: theme.palette.background.paper,
		width: 500,
	},
});

class NewsFeed extends Component {
	constructor() {
		super();
		this.state = {
			items: [],
			currentFeed: 'Welcome to Ethnus',
			currentFeedContent: 'To enable <b>wow</b> and <b>delightful</b> careers',
			step: 0,
			pubDate: 'Be a proud enthusite',
			currentSearchIndex: 0
		};
	}

	pickContent = () => {

		let that = this;

		let copiedStates = Object.assign({}, that.state);
		copiedStates.step++;

		if(copiedStates.step % 5 === 0) {
			that.setState(copiedStates, function() {
				return that.getContent();
			});
			return;
		}

		setTimeout(function() {
			that.pickContent();
		}, 12000);

		let currentIndex = Math.round(Math.random() * (that.state.items.length - 1));
		copiedStates.currentFeed = that.state.items[currentIndex].title;
		copiedStates.currentFeedContent = that.state.items[currentIndex].content;
		copiedStates.pubDate = that.state.items[currentIndex].pubDate;
		// copiedStates.currentFeedContent = that.state.items[currentIndex].description;
		that.setState(copiedStates);
	}

	getContent = () => {

		function shuffleArray(array) {
			for (var i = array.length - 1; i > 0; i--) {
				var j = Math.floor(Math.random() * (i + 1));
				var temp = array[i];
				array[i] = array[j];
				array[j] = temp;
			}
			return array;
		}


		let feeds = shuffleArray([
			'https://www.thehindu.com/elections/feeder/default.rss',
			'https://www.thehindu.com/news/national/feeder/default.rss',
			'https://www.thehindu.com/business/feeder/default.rss',
			'https://www.thehindu.com/sport/feeder/default.rss',
			'https://www.thehindu.com/entertainment/feeder/default.rss'
			]);

		const CORS_PROXY = "https://cors-anywhere.herokuapp.com/"
		let copiedStates = Object.assign({}, this.state);
		copiedStates.currentSearchIndex++;

		let parser = new Parser();
		let that = this;

		let searchKeys = ["Technology", "India", "Cricket", "Election", "Bangalore"];
		if(copiedStates.currentSearchIndex >= searchKeys.length) {
			copiedStates.currentSearchIndex = 0;
		}
		
		let feed = parser.parseURL(CORS_PROXY + feeds[0], function(err, feed) {
			copiedStates.items = feed.items;
			that.setState(copiedStates, function() {
				that.pickContent();
			});
		});
	}

	componentDidMount() {

		let that = this;
		setTimeout(function() {
			that.getContent();
		}, 12000);
		
	}
	render() {

		let bgImgPath = "bg-" + this.state.step % 4 + ".jpg";
		let pubDate = this.state.pubDate;
		if(pubDate !== "Be a proud enthusite") {
			pubDate = pubDate.split(" ");
			pubDate.splice(-1,1);
			pubDate = pubDate.join(" ");
		}

		return (
			<div>
			<Card>
			<CardContent style={{
				height: '100vh',
				backgroundColor: "#2B2D42",
				backgroundImage: 'url(bg/'+ bgImgPath +')',
				backgroundSize: 'cover',
				display: 'table',
				padding: 0,
				width: '100%',
				color: '#EDF2F4',
			}}>
			<Typography component="div" style={{
				display: 'table-cell',
				width: '100%',
				padding: 0,
				background: 'rgba(0, 0, 0, 0.7)',
				verticalAlign: 'middle',
				textAlign: 'center',
				fontSize: '40px',
				color: '#EDF2F4',
			}}>
			{this.state.currentFeed}
			<Typography style={{
				color: '#EDF2F4',
				textAlign: 'center',
				fontSize: '16px',
			}}>{pubDate}</Typography>
			<hr style={{
				borderTop: '1px solid #EDF2F4',
				width: '15%'
			}} />
			<Typography component="div" style={{
				fontSize: '25px',
				color: '#EDF2F4',
				width: '65%',
				margin: 'auto'
			}}
			dangerouslySetInnerHTML={{__html: this.state.currentFeedContent}}>
			</Typography>
			</Typography>
			</CardContent>
			</Card>
			</div>
			);
	}
}

export default withStyles(styles)(NewsFeed);
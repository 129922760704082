import React, { Component} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import PersonIcon from '@material-ui/icons/Person';
import MenuIcon from '@material-ui/icons/Menu';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import { Link } from 'react-router-dom';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import Home from '@material-ui/icons/Home';

const styles = theme => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
});

const linkStyle = {
	textDecoration: 'none',
	color: 'unset',
	border: 'none',
	'outline': 'none'
};

class Header extends Component {
	constructor(props){
		super();
		this.state = {
			drawer: false,
			anchorEl: null,
			menuOpen: false
		}
		this.toggleDrawer = this.toggleDrawer.bind(this);
	}
	toggleDrawer = () => {
		this.setState({
			drawer: !this.state.drawer
		});
	}
	render(){

		const sideList = [
		<ListItem key={"justLogo"}>
		<ListItemIcon>
		<Avatar src="justLogo.jpg" style={{
			width: 50,
			height: 50
		}} />
		</ListItemIcon>
		<ListItemText primary="ETHNUS" />
		</ListItem>,
		<Divider key={"divider"} />,
		<Link to="/" style={linkStyle} >
		<ListItem button key={"toggleDrawer1"}
		onClick={this.toggleDrawer} >
		<ListItemIcon>
		<Home />
		</ListItemIcon>
		<ListItemText primary="Home" />
		</ListItem>
		</Link>,
		<Divider />,
		<Link to="/visitor" style={linkStyle} >
		<ListItem button key={"toggleDrawer2"}
		onClick={this.toggleDrawer} >
		<ListItemIcon>
		<SupervisorAccountIcon />
		</ListItemIcon>
		<ListItemText primary="Visitor" />
		</ListItem>
		</Link>,
		<Divider />,
		<Link to="/scan" style={linkStyle} >
		<ListItem button key={"toggleDrawer3"}
		onClick={this.toggleDrawer} >
		<ListItemIcon>
		<PersonIcon />
		</ListItemIcon>
		<ListItemText primary="Associate" />
		</ListItem>
		</Link>,
		];
		const { classes } = this.props;
		return(
			<div>
			<Drawer
			open={this.state.drawer}
			onClose={this.toggleDrawer}
			>
			<List component="nav">
			{sideList}
			</List>
			</Drawer>
			<AppBar position="static" className="colorFullAppBar">
			<Toolbar>
			<IconButton
			onClick={this.toggleDrawer}
			onKeyDown={this.toggleDrawer}
			className={classes.menuButton}
			color="inherit"
			aria-label="Menu">
			<MenuIcon />
			</IconButton>
			<Typography variant="title" color="inherit" className={classes.display}>
			Ethnus Attendance & Visitor Management
			</Typography>
			</Toolbar>
			</AppBar>
			</div>

			)
	}
}
Header.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Header);